<template>
  <v-fade-transition leave-absolute>
    <v-overlay :value="updating" v-if="updating" absolute>
      <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
    </v-overlay>
    <div class="super-rgpd-create" v-else>
      <UiBanner fluid :title="$t('superRgpd.create.name')" :breadcrumbs="breadcrumbs" />
      <UiContainer large with-actions>
        <form class="super-rgpd__form" @submit.prevent="onSubmit" novalidate autocomplete="off">
          <section class="super-rgpd__form__section">
            <UiTitle :title="$t('superRgpd.create.game.title')" />
            <div class="super-rgpd__form__section__grid">
              <ui-block id="gameDateLabel" :label="$t('superRgpd.create.game.gameDate.label')">
                <template v-slot:body>
                  <v-menu
                    v-model="modalDateOpen"
                    :close-on-content-click="false"
                    :nudge-right="20"
                    :nudge-top="20"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="superRgpdModel.game_date"
                        solo
                        flat
                        outlined
                        :prepend-inner-icon="icons.mdiCalendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        :placeholder="$t('superRgpd.create.game.gameDate.placeholder')"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="superRgpdModel.game_date" @input="modalDateOpen = false"></v-date-picker>
                  </v-menu>
                </template>
              </ui-block>
              <ui-block
                id="gameReferenceLabel"
                class="required-asterisk"
                :label="$t('superRgpd.create.game.gameReference.label')"
              >
                <template v-slot:body>
                  <v-combobox
                    :value="superRgpdModel.game_reference"
                    :item-text="item => item.game_reference + ' - ' + item.name"
                    item-value="id"
                    id="game_reference"
                    solo
                    flat
                    outlined
                    hide-no-data
                    :placeholder="$t('superRgpd.create.game.gameReference.placeholder')"
                    :prepend-inner-icon="icons.mdiBarcode"
                    :items="gameReferences"
                    @input.native="onInput($event.target.value)"
                    @change="setGameReference"
                    :error-messages="gameReferenceErrors"
                  />
                </template>
              </ui-block>
            </div>
          </section>

          <section class="super-rgpd__form__section">
            <UiTitle :title="$t('superRgpd.create.general.title')" />

            <div class="super-rgpd__form__section__grid">
              <ui-block id="firstnameLabel" :label="$t('superRgpd.create.general.firstName.label')">
                <template v-slot:body>
                  <v-text-field
                    v-model="superRgpdModel.firstname"
                    id="firstname"
                    solo
                    flat
                    outlined
                    :placeholder="$t('superRgpd.create.general.firstName.placeholder')"
                    :prepend-inner-icon="icons.mdiBadgeAccountHorizontal"
                  />
                </template>
              </ui-block>
              <ui-block id="lastNameLabel" :label="$t('superRgpd.create.general.lastName.label')">
                <template v-slot:body>
                  <v-text-field
                    v-model="superRgpdModel.lastname"
                    id="lastName"
                    solo
                    flat
                    outlined
                    :placeholder="$t('superRgpd.create.general.lastName.placeholder')"
                    :prepend-inner-icon="icons.mdiBadgeAccountHorizontalOutline"
                  />
                </template>
              </ui-block>
              <ui-block class="sm:tw-col-span-2" id="emailLabel" :label="$t('superRgpd.create.general.email.label')">
                <template v-slot:body>
                  <v-text-field
                    v-model="superRgpdModel.email"
                    id="email"
                    solo
                    flat
                    outlined
                    :placeholder="$t('superRgpd.create.general.email.placeholder')"
                    :prepend-inner-icon="icons.mdiEmail"
                    :error-messages="emailErrors"
                  />
                </template>
              </ui-block>
            </div>
          </section>

          <section class="super-rgpd__form__section">
            <UiTitle :title="$t('superRgpd.create.location.title')" />
            <div class="super-rgpd__form__section__grid">
              <ui-block id="addressLabel" :label="$t('superRgpd.create.location.address.label')">
                <template v-slot:body>
                  <v-text-field
                    v-model="superRgpdModel.address"
                    id="address"
                    solo
                    flat
                    outlined
                    :placeholder="$t('superRgpd.create.location.address.placeholder')"
                    :prepend-inner-icon="icons.mdiMapMarker"
                  />
                </template>
              </ui-block>
              <ui-block id="postcodeLabel" :label="$t('superRgpd.create.location.postcode.label')">
                <template v-slot:body>
                  <v-text-field
                    v-model="superRgpdModel.postcode"
                    id="postcode"
                    solo
                    flat
                    outlined
                    :placeholder="$t('superRgpd.create.location.postcode.placeholder')"
                    :prepend-inner-icon="icons.mdiNumeric"
                  />
                </template>
              </ui-block>
              <ui-block id="cityLabel" :label="$t('superRgpd.create.location.city.label')">
                <template v-slot:body>
                  <v-text-field
                    v-model="superRgpdModel.city"
                    id="city"
                    solo
                    flat
                    outlined
                    :placeholder="$t('superRgpd.create.location.city.placeholder')"
                    :prepend-inner-icon="icons.mdiCityVariant"
                  />
                </template>
              </ui-block>
              <ui-block id="countryLabel" :label="$t('superRgpd.create.location.country.label')">
                <template v-slot:body>
                  <v-text-field
                    v-model="superRgpdModel.country"
                    id="country"
                    solo
                    flat
                    outlined
                    :placeholder="$t('superRgpd.create.location.country.placeholder')"
                    :prepend-inner-icon="icons.mdiEarth"
                  />
                </template>
              </ui-block>
            </div>
          </section>

          <UiActions large centered>
            <v-btn type="submit" rounded color="primary" :loading="saving" :disabled="saving">
              <v-icon dense left> {{ icons.mdiPlus }} </v-icon>
              {{ $t('button.create') }}
            </v-btn>
          </UiActions>
        </form>
      </UiContainer>
    </div>
  </v-fade-transition>
</template>

<script>
import {
  mdiBadgeAccountHorizontal,
  mdiBadgeAccountHorizontalOutline,
  mdiEmail,
  mdiMapMarker,
  mdiNumeric,
  mdiCityVariant,
  mdiEarth,
  mdiCalendar,
  mdiPlus,
  mdiBarcode,
} from '@mdi/js'
import { mapActions, mapState } from 'vuex'
import { validationMixin } from 'vuelidate'
import { debounce } from '@/utils/debounce.util'
import { email, required } from 'vuelidate/lib/validators'
import UiBanner from '@/components/UI/Banner.vue'
import UiContainer from '@/components/UI/Container.vue'
import UiActions from '@/components/UI/Actions.vue'
import UiTitle from '@/components/UI/Title.vue'
import UiBlock from '@/components/UI/Block.vue'

export default {
  name: 'SuperRgpdCreate',
  components: {
    UiBanner,
    UiContainer,
    UiActions,
    UiTitle,
    UiBlock,
  },
  mixins: [validationMixin],
  data: () => ({
    icons: {
      mdiBadgeAccountHorizontal,
      mdiBadgeAccountHorizontalOutline,
      mdiEmail,
      mdiMapMarker,
      mdiNumeric,
      mdiCityVariant,
      mdiEarth,
      mdiCalendar,
      mdiPlus,
      mdiBarcode,
    },
    saving: false,
    superRgpdModel: {},
    modalDateOpen: false,
  }),
  async created() {
    this.setUpdating(true)
    await this.getAuthentication()
    this.setUpdating(false)
  },
  computed: {
    ...mapState({
      updating: state => state.backoffice.updating,
      gameReferences: state => state.superRgpd.gameReferences,
    }),
    breadcrumbs() {
      return [
        {
          text: this.$t('superRgpd.list.name'),
          to: {
            name: 'SuperRgpdList',
          },
        },
        {
          text: this.$t('superRgpd.create.name'),
          to: {
            name: 'SuperRgpdCreate',
          },
        },
      ]
    },
    emailErrors() {
      const errors = []
      if (!this.$v.superRgpdModel.email.$dirty) return errors
      !this.$v.superRgpdModel.email.email && errors.push(this.$t('error.email'))
      return errors
    },
    gameReferenceErrors() {
      const errors = []
      if (!this.$v.superRgpdModel.game_reference.game_reference.$dirty) return errors
      !this.$v.superRgpdModel.game_reference.game_reference.required && errors.push(this.$t('error.required'))
      return errors
    },
  },
  methods: {
    ...mapActions({
      getAuthentication: 'backoffice/getAuthentication',
      setUpdating: 'backoffice/setUpdating',
      setAlert: 'backoffice/setAlert',
      getGameReferences: 'superRgpd/getGameReferences',
      createSuperRgpd: 'superRgpd/createSuperRgpd',
    }),
    onInput: debounce(function (value) {
      this.getGameReferences(value)
    }, 300),
    setGameReference(value) {
      this.superRgpdModel.game_reference = value
    },
    async onSubmit() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.saving = true

        try {
          const sendRgpdCreation = {
            ...this.superRgpdModel,
            game_reference: this.superRgpdModel.game_reference.game_reference,
          }
          await this.createSuperRgpd(sendRgpdCreation)
          this.showNotificationSuccess()
          this.$router.push({ name: 'SuperRgpdList' })
        } catch {
          this.showNotificationError()
        }
      }
    },
    showNotificationError() {
      this.setAlert({
        color: 'error',
        text: this.$t('notification.error.default'),
      })
      this.saving = false
    },
    showNotificationSuccess() {
      this.setAlert({
        color: 'success',
        text: this.$t('notification.create.client', { client: this.superRgpdModel.name }),
      })
      this.saving = false
    },
  },
  validations() {
    return {
      superRgpdModel: {
        email: {
          email,
        },
        game_reference: {
          game_reference: {
            required,
          },
        },
      },
    }
  },
}
</script>

<style lang="scss" scoped>
.super-rgpd {
  @apply tw-h-full;

  &__form {
    &__section {
      @apply tw-mt-4;

      &:first-child {
        @apply tw-mt-0;
      }

      &__grid {
        @apply tw-grid sm:tw-grid-cols-2 tw-gap-x-6 tw-w-full tw-mt-6;
      }
    }
  }
}
</style>
